<template>
  <div
    class="image-wrapper"
    :class="{loading: isLoading}">
    <img
      class="eevee"
      :class="getMoodClassFromStorage"
      :src="getMoodSpritePathFromStorage"
      alt="">
  </div>
</template>

<script>
import { spriteImagePaths } from '@/sprites'

export default {
  computed: {
    getMoodSpritePathFromStorage () {
      return this.$store.state.moodSpritePath
    },
    getMoodClassFromStorage () {
      return this.$store.state.moodClass
    }
  },
  data: function () {
    return {
      isLoading: true
    }
  },
  name: 'Evoli',
  methods: {
    initEevee () {
      if (this.$store.state.isNight) {
        this.setEeveeMood('sleepyHalf')

        window.setTimeout(() => {
          this.setEeveeMood('sleepy')
        }, 2000)
      } else {
        this.setEeveeMood('happy2')

        window.setTimeout(() => {
          this.setEeveeMood('normal')
        }, 2500)
      }
    },
    addPetEventListeners () {
      const eeveeElement = document.querySelector('.eevee')
      const startEvents = ['touchstart', 'mouseenter']
      const endEvents = ['touchend', 'mouseleave']

      startEvents.forEach(event => {
        eeveeElement.addEventListener(event, () => {
          if (this.$store.state.isNight) {
            this.setEeveeMood('sleepy2')
          } else {
            this.setEeveeMood('happy')
          }
        })
      })

      endEvents.forEach(event => {
        eeveeElement.addEventListener(event, () => {
          if (this.$store.state.isNight) {
            this.setEeveeMood('sleepy')
          } else {
            this.setEeveeMood('normal')
          }
        })
      })
    },
    setEeveeMood (mood) {
      this.$store.commit('setMoodSpritePath', spriteImagePaths[mood])
      this.$store.commit('setMoodClass', mood)
    },
    loadImagesToCache: function () {
      let loadedImages = 0

      for (const path in spriteImagePaths) {
        const image = new Image()
        image.src = spriteImagePaths[path]
        image.onload = () => {
          loadedImages++

          if (loadedImages === Object.keys(spriteImagePaths).length) {
            console.log('Images loaded')
            this.isLoading = false

            this.initEevee()
            this.addPetEventListeners()
          }
        }
      }
    }
  },
  mounted: function () {
    this.loadImagesToCache()
  }
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  position: relative;
  min-height: 200px;
  margin: auto;
  max-width: 500px;

  &.loading {
    &::before {
      animation: loading 1s infinite ease-in-out;
      content: '';
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      background-image: url("/img/loading.svg");
      top: 50%;
      left: 50%;
      transform-origin: center center;
    }
  }
}

.eevee {
  cursor: pointer;
  display: block;
  width: 90%;
  margin: auto;
  transform-origin: center;

  &.happy {
    animation: eevee-updown 1s alternate ease-in-out infinite;
  }

  &.sleepy {
    animation:  eevee-updown 5s forwards ease-in-out infinite;
  }

  &.sleepy2 {
    animation: eevee-leftright-lighter 3s alternate ease-in-out infinite;
    transform-origin: bottom center;
  }

  &.sleepyHalf {
    animation:  eevee-updown 5s forwards ease-in-out infinite;
  }

  &.eating {
    animation:  eevee-eat 0.25s forwards ease-in-out alternate infinite;
  }

  &.sleepyEating {
    animation:  eevee-eat 0.5s forwards ease-in-out alternate infinite;
  }

  &.sad,
  &.sleepySad {
    animation:  eevee-sob 1s forwards ease-in-out infinite;
  }

  &.happy2 {
    animation: eevee-leftright 1s alternate ease-in-out infinite;
    transform-origin: bottom center;
  }

  &.normal {
    animation:  eevee-updown 2.5s forwards ease-in-out infinite;
  }
}

@keyframes loading {
  from {transform: translate(-50%, -50%) rotate(0deg)}
  to {transform: translate(-50%, -50%) rotate(360deg)}
}

@keyframes eevee-updown {
  50% {transform: translateY(10px)}
}

@keyframes eevee-sob {
  0% {transform: translateY(0) scaleX(1.10) scaleY(0.90)}
  20% {transform: translateY(-20px) scaleX(0.90) scaleY(1.10)}
  40% {transform: translateY(0) scaleX(1) scaleY(1)}
  100% {transform: translateY(0) scaleX(1) scaleY(1)}
}

@keyframes eevee-eat {
  0% {transform: translateY(0) scaleX(1) scaleY(1)}
  100% {transform: translateY(0) scaleX(1.10) scaleY(0.90)}
}

@keyframes eevee-leftright {
  0% {transform: rotate(-10deg)}
  100% {transform: rotate(10deg)}
}

@keyframes eevee-leftright-lighter {
  0% {transform: rotate(-5deg)}
  100% {transform: rotate(5deg)}
}
</style>
