<template>
  <div id="app" class="app" :class="{day: isDay}">
    <Options />

    <Counter />

    <div class="app__inner">
      <Evoli />

      <div class="router-view-wrapper">
        <router-view/>
      </div>
    </div>

    <Menu />
  </div>
</template>

<script>
import Evoli from './components/Evoli.vue'
import Counter from './components/Counter.vue'
import Menu from './components/Menu.vue'
import Options from './components/Options.vue'
import GeoLord from '@/geoLord'

export default {
  name: 'App',
  components: {
    Evoli,
    Counter,
    Menu,
    Options
  },
  computed: {
    isDay () {
      return this.$store.state.isDay
    }
  },
  mounted: function () {
    GeoLord.setLocation(this.$store)

    window.setInterval(() => {
      GeoLord.setLocation(this.$store)
    }, 500)
  }
}
</script>

<style lang="scss">
:root {
  --dark-bg-color: #2D3436;
  --theme-color: #00b894;
}

body,
html {
  margin: 0;
  padding: 0;
}

html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;900&display=swap');

* {
  box-sizing: border-box;
  font-family: Nunito, sans-serif;
}

.app {
  background-color: var(--dark-bg-color);
  display: flex;
  flex-direction: column;
  padding: 15px 15px 90px 15px;
  height: 100vh;
  width: 100vw;
  transition: background-color 0.25s ease;

  .bottom-elements {
    margin-top: auto;
  }

  &.day {
    background-color: white;
  }

  .router-view-wrapper {
    position: fixed;
    bottom: 80px;
    left: 15px;
    width: calc(100vw - 30px);

    @media (min-width: 720px) {
      max-width: 500px;
      margin: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
