import timeLord from '@/timeLord'

class GeoLord {
  initialConfig (store) {
    const date = new Date()
    const hours = this.padWithZero(date.getHours())
    const minutes = this.padWithZero(date.getMinutes())
    const isDay = hours >= 8 && hours < 18
    const isNight = hours >= 23 || hours < 6

    store.commit('setMinutes', minutes)
    store.commit('setHours', hours)
    store.commit('setIsDay', isDay)
    store.commit('setIsNight', isNight)
  }

  padWithZero (number) {
    return ('0' + number).slice(-2)
  }

  setLocation (store) {
    this.initialConfig(store)

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        store.commit('setLat', position.coords.latitude)
        store.commit('setLng', position.coords.longitude)
        timeLord.setTime(store)
        // weatherLord.setWeather(store)
      }, () => {
        timeLord.setTime(store)
        // weatherLord.setWeather(store)
      })
    }
  }
}

export default new GeoLord()
