<template>
  <div class="bottom-elements">
    <Dialogue />
  </div>
</template>

<script>
import Dialogue from '../components/Dialogue.vue'

export default {
  name: 'App',
  components: {
    Dialogue
  }
}
</script>
