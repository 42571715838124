import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    icon: '/img/icons/home.svg',
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    icon: '/img/icons/mag.svg',
    path: '/search',
    name: 'Suche',
    component: () => import('../views/Search.vue')
  },
  {
    icon: '/img/icons/food.svg',
    path: '/food',
    name: 'Füttern',
    component: () => import('../views/Food.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
