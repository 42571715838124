import SunCalc from 'suncalc'

class TimeLord {
  setTime (store) {
    const date = new Date()
    const hours = this.padWithZero(date.getHours())
    const minutes = this.padWithZero(date.getMinutes())
    const sunset = SunCalc.getTimes(new Date(), store.state.latitude, store.state.longitude).sunset.getHours()
    const sunrise = SunCalc.getTimes(new Date(), store.state.latitude, store.state.longitude).sunrise.getHours()
    const isDay = hours >= sunrise && hours < sunset
    const isNight = hours >= 23 || hours < 6

    store.commit('setMinutes', minutes)
    store.commit('setHours', hours)
    store.commit('setIsDay', isDay)
    store.commit('setIsNight', isNight)
  }

  padWithZero (number) {
    return ('0' + number).slice(-2)
  }
}

export default new TimeLord()
