<template>
  <div class="options" :class="{open: isOpen}">
    <div class="headline" :class="{'small': isSmallHeadline}">
      <span v-if="inputValue">Hallo, {{inputValue}}!</span>
      <span v-else>Hallo!</span>
    </div>

    <div class="options__inner">
      <div>
        <div class="label">Name</div>

        <input v-model="inputValue"  type="text" placeholder="Gib deinen Namen ein!"/>
      </div>

      <div>
        <div class="label">Lieblingsfarbe</div>

        <div class="color-picker">
          <div
            @click="setColor(color)"
            v-for="color in colors"
            :key="color"
            :style="{'background-color': color}"></div>
        </div>
      </div>

      <!--
      <div class="language-selects">
        <button class="de"></button>
        <button class="en"></button>
      </div>
      -->

      <button class="submit" :class="{'enable': showSubmit}" @click="this.commitOptions">Speichern!</button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isSmallHeadline () {
      return this.inputValue.length >= 5
    },
    showSubmit () {
      return this.currentColor.length >= 1 && this.inputValue.length >= 1
    },
    isOpen () {
      return this.$store.state.optionsIsOpen
    }
  },
  data: function () {
    return {
      currentColor: this.$store.state.themeColor,
      colors: [
        '#00b894',
        '#00cec9',
        '#0984e3',
        '#6c5ce7',
        '#fdcb6e',
        '#e17055',
        '#d63031',
        '#e84393'
      ],
      inputValue: this.$store.state.userName
    }
  },
  methods: {
    setColor (color) {
      document.documentElement.style.setProperty('--theme-color', color)
      this.currentColor = color
    },
    commitOptions () {
      this.$store.commit('setUsername', this.inputValue)
      this.$store.commit('setThemeColor', this.currentColor)
      localStorage.setItem('userName', this.inputValue)
      localStorage.setItem('themeColor', this.currentColor)
      this.$store.commit('setOptionsIsOpen', false)
    }
  },
  mounted () {
    this.setColor(this.currentColor)
  },
  name: 'Options'
}
</script>

<style lang="scss" scoped>
.options {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--dark-bg-color);
  z-index: 5;
  padding: 40px 20px;
  transition: top 0.25s ease-out;

  &::after {
    background-color: var(--theme-color);
    content: "";
    display: block;
    height: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: height 0.25s ease-out;
  }

  &:not(&.open) {
    top: -100vh;
    transition-delay: 0.25s;

    &::after {
      height: 100%;
    }
  }

  &__inner {
    max-width: 720px;
    margin: auto;

    > * {
      margin-bottom: 30px;

      @media (min-width: 720px) {
        margin-bottom: 60px;
      }
    }
  }

  .headline {
    color: var(--theme-color);
    font-size: 50px;
    font-weight: 900;
    line-height: 70px;
    margin-bottom: 80px;
    position: relative;
    text-align: center;
    transition: color 0.25s ease-out;

    @media (min-width: 720px) {
      font-size: 70px;
    }

    &.small {
      font-size: 35px;

      @media (min-width: 720px) {
        font-size: 60px;
      }
    }
  }

  .label {
    color: var(--theme-color);
    font-size: 16px;
    margin-bottom: 5px;

    @media (min-width: 720px) {
      margin-bottom: 10px;
    }

    a {
      color: white !important;
    }
  }

  .color-picker {
    display: flex;

    &:hover {
      div {
        &:not(:hover) {
          opacity: 0.5;
        }
      }
    }

    div {
      cursor: pointer;
      display: block;
      background-color: red;
      box-shadow: none;
      height: 28px;
      width: 28px;
      border-radius: 5px;
      transition: transform 0.25s ease-out, opacity 0.25s ease-out;
      transform: scale(1);

      @media (min-width: 720px) {
        border-radius: 10px;
        height: 50px;
        width: 50px;
      }

      &:hover {
        transform: scale(1.1);
      }

      &:not(:last-child) {
        margin-right: 20px;

        @media (min-width: 720px) {
          margin-right: 50px;
        }
      }
    }
  }

  input {
    background-color: var(--theme-color);
    border: none;
    border-radius: 15px;
    color: white;
    font-size: 18px;
    padding: 15px;
    transform: scale(1);
    width: 100%;
    transition: background-color 0.25s ease-out;

    &::placeholder {
      color: white;
    }

    &:focus,
    &:hover {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  .submit {
    cursor: pointer;
    background-color: var(--theme-color);
    border: none;
    border-radius: 15px;
    color: white;
    font-size: 18px;
    padding: 15px;
    transform: scale(0);
    width: 100%;
    margin-top: 20px;
    transition: background-color 0.25s ease-out;

    &:focus,
    &:hover {
      border: none;
      box-shadow: none;
      outline: none;
    }

    &.enable {
      animation: pop-in 0.25s ease forwards;
    }
  }

  .language-selects {
    display: flex;
    justify-content: center;

    &:hover {
      button {
        &:not(:hover) {
          opacity: 0.5;
        }
      }
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: block;
      box-shadow: none;
      height: 28px;
      width: 28px;
      border-radius: 5px;
      transition: transform 0.25s ease-out, opacity 0.25s ease-out;
      transform: scale(1);

      @media (min-width: 720px) {
        border-radius: 10px;
        height: 50px;
        width: 50px;
      }

      &:hover {
        transform: scale(1.1);
      }

      &:not(:last-child) {
        margin-right: 20px;

        @media (min-width: 720px) {
          margin-right: 50px;
        }
      }

      &.de {
        background-image: url('/img/icons/de.svg');
        background-size: cover;
        background-position: center center;
      }

      &.en {
        background-image: url('/img/icons/en.svg');
        background-size: cover;
        background-position: center center;
      }
    }
  }
}

@keyframes pop-in {
  from { transform: scale(0)}
  to { transform: scale(1)}
}
</style>
