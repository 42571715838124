<template>
  <div class="menu" :class="{open: isOpen}">
    <div class="menu__trigger" @click="isOpen = !isOpen">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div class="menu__layer">
      <ul>
        <li @click="linkClickHandler" v-for="(route, i) in routes" :key="i">
          <router-link :to="route.path">
            <img :src="route.icon" alt="">

            {{route.name}}
          </router-link>
        </li>

        <li @click="optionsClickHandler">
          <img src="/img/icons/options.svg" alt="">

          Optionen
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    routes () {
      return this.$router.options.routes
    },
    currentRoute () {
      return this.$route.name
    }
  },
  data: function () {
    return {
      isOpen: false
    }
  },
  methods: {
    linkClickHandler () {
      window.setTimeout(() => {
        this.isOpen = false
      }, 1000)
    },
    optionsClickHandler () {
      this.linkClickHandler()

      this.$store.commit('setOptionsIsOpen', true)
    }
  },
  name: 'Menu'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.menu {
  &.open {
    .menu {
      &__layer {
        transform: translateX(0);
      }

      &__trigger {
        div {
          top: 50%;

          &:first-child {
            transform: translateX(-50%) rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            transform: translateX(-50%) rotate(-45deg);
          }
        }
      }
    }
  }

  &__layer {
    color: white;
    font-size: 25px;
    font-weight: 900;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: var(--theme-color);
    transform: translateX(100vw);
    transition: transform 0.25s ease-out;
    padding: 20px;

    @media (min-width: 720px) {
      padding: 20px 50px
    }

    ul  {
      list-style-type: none;
      margin: 0;
      padding: 0;

      @media (min-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      li {
        cursor: pointer;
        opacity: 1;
        text-transform: uppercase;
        margin-bottom: 15px;
        text-decoration: none;
        position: relative;

        @media (min-width: 720px) {
          display: block;
        }

        &:nth-child(3) {
          @media (min-width: 720px) {
            display: none;
          }
        }

        &:not(:last-child) {
          @media (min-width: 720px) {
            margin-right: 80px;
          }
        }

        img {
          display: none;

          @media (min-width: 720px) {
            display: block;
            margin: 0 auto 30px auto;
            width: 77px;
            height: 77px;
          }
        }
      }

      li a {
        color: white !important;
        opacity: 1 !important;
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 15px;
        text-decoration: none;
        position: relative;

        @media (min-width: 720px) {
          margin-bottom: 0;
        }

        &::before {
          position: absolute;
          content: "";
          left: 0;
          bottom: -5px;
          height: 5px;
          width: 0;
          background-color: white;
          transition: width 0.25s ease-out;
        }

        &.router-link-exact-active {
          animation: flash 0.25s;
          animation-iteration-count: 2;
          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  &__trigger {
    animation: menu-from-bottom 0.5s forwards;
    animation-delay: 0.75s;
    background-color: var(--theme-color);
    border: none;
    border-radius: 54px;
    cursor: pointer;
    display: block;
    height: 54px;
    width: 54px;
    position: fixed;
    right: 15px;
    bottom: 15px;
    transform: scale(1) translateY(100px);
    z-index: 2;
    transition: transform 0.25s ease;

    &:active {
      transform: scale(0.9);
    }

    div {
      position: absolute;
      height: 3px;
      width: 50%;
      background-color: white;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.25s ease, transform 0.25s ease, top 0.25s ease;

      &:first-child {
        top: calc(50% - 10px);
      }

      &:nth-child(2) {
        top: 50%;
      }

      &:last-child {
        top: calc(50% + 10px);
      }
    }
  }
}

@keyframes flash {
  from { opacity: 1 }
  to { opacity: 0.5 }
}

@keyframes menu-from-bottom {
  from { transform: scale(1) translateY(100px) }
  to { transform: scale(1) translateY(0) }
}

@keyframes fade-from-top {
  from { opacity: 0; transform: translateY(-10px) }
  to {opacity: 1; transform: translateY(0)}
}
</style>
