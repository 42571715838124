import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    themeColor: localStorage.getItem('themeColor') || '#00b894',
    userName: localStorage.getItem('userName') || '',
    moodSpritePath: '',
    moodClass: '',
    weatherTemp: '',
    weatherDescription: '',
    weatherIcon: '',
    hours: '00',
    minutes: '00',
    isDay: 'false',
    isNight: 'false',
    latitude: '51.4556',
    longitude: '7.0116',
    optionsIsOpen: !localStorage.getItem('userName')
  },
  mutations: {
    setHours (state, hours) {
      state.hours = hours
    },
    setMinutes (state, minutes) {
      state.minutes = minutes
    },
    setIsDay (state, isDay) {
      state.isDay = isDay
    },
    setIsNight (state, isNight) {
      state.isNight = isNight
    },
    setMoodClass (state, className) {
      state.moodClass = className
    },
    setMoodSpritePath (state, spritepath) {
      state.moodSpritePath = spritepath
    },
    setThemeColor (state, themecolor) {
      state.themeColor = '#' + themecolor
    },
    setUsername (state, username) {
      state.userName = username
    },
    setWeatherDescription (state, weatherDescription) {
      state.weatherDescription = weatherDescription
    },
    setWeatherIcon (state, weatherIcon) {
      state.weatherIcon = weatherIcon
    },
    setWeatherTemp (state, weatherTemp) {
      state.weatherTemp = weatherTemp
    },
    setLat (state, lat) {
      state.latitude = lat
    },
    setLng (state, lng) {
      state.longitude = lng
    },
    setOptionsIsOpen (state, isOpen) {
      state.optionsIsOpen = isOpen
    }
  },
  actions: {
  },
  modules: {
  }
})
