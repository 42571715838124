<template>
  <div>
    <!--
    <div class="weather" :class="{day: isDay}">
      <p>{{weatherDescription}}, {{temparature}}°C</p>

      <div class="image-wrapper">
        <img :src="weatherIcon" alt="">
      </div>
    </div>
    -->

    <div class="dialogue">
      <span v-if="isDay">
        Hallo {{username}}! Ich hoffe, dass du einen schönen Tag hast!
      </span>
      <span v-else-if="isNight">
        Zzz... uh, du bist noch wach {{username}}? Ich... schlafe noch etwas...
        Gute Nacht...
      </span>
      <span v-else>
        Oh, guten Abend {{username}}! Hattest du einen schönen Tag?
      </span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    username () {
      return this.$store.state.userName
    },
    temparature () {
      return this.$store.state.weatherTemp
    },
    weatherDescription () {
      return this.$store.state.weatherDescription
    },
    weatherIcon () {
      return this.$store.state.weatherIcon
    },
    isDay () {
      return this.$store.state.isDay
    },
    isNight () {
      return this.$store.state.isNight
    }
  },
  name: 'Dialogue'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dialogue {
  background-color: var(--theme-color);
  animation: pop-in 0.25s ease forwards;
  border-radius: 15px;
  color: white;
  font-size: 18px;
  min-height: 100px;
  padding: 15px;
  position: relative;
  margin-top: auto;

  .quote {
    margin-bottom: 0;

    span {
      font-size: 14px;
    }
  }
}

.weather {
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  color: var(--theme-color);
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 5px;

  p {
    margin: 0;
  }

  .image-wrapper {
    background-color: var(--theme-color);
    margin-left: 10px;
    border-radius: 30px;
    transition: background-color 0.25s ease-out;
  }

  img {
    display: block;
    height: 30px;
    width: 30px;
  }
}

@keyframes pop-in {
  from { transform: scale(0)}
  to { transform: scale(1)}
}
</style>
